var $ = require('jquery');

var mobileNavMenu = function() {
    $('.hamburger').on('click', function() {
        $('.nav').toggleClass('open');
    });

    $('.nav a').on('click', function() {
        $('.nav').removeClass('open');
    });

    $('.nav__logo')
    .clone()
    .addClass('nav__logo--clone')
    .attr('id', '')
    .attr('data-placeholder-label', '')
    .prependTo('.nav');

}

module.exports = mobileNavMenu;