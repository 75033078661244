var scrollToSection = require('./components/scrollToSection');
var mobileNavMenu = require('./components/mobileNavMenu');
var activeLink = require('./components/activeLink');
var repositionOnLoad = require('./components/repositionOnLoad');
var productPageDetection = require('./components/productPageDetection');
var carousel = require('./components/carousel');
var embedVideo = require('./components/embedVideo');
require('./components/smallBannerGroup');
require('./components/cookies');

// document.addEventListener('DOMContentLoaded', function() {
window.onload = function() {

  document.body.setAttribute('id','home');

  repositionOnLoad();
  scrollToSection();
  mobileNavMenu();
  productPageDetection();
  carousel();
  embedVideo();
  activeLink();

};