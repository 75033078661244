var utility = require('../vendor/utilityFunctions');

var scrollToSection = function() {


  var navLinks = Array.prototype.slice.call(document.querySelectorAll('a[href^="/#"]'));
  navLinks.map(function(currentValue, index, array) {
      currentValue.addEventListener('click', function(e) {

        // e.preventDefault();
        var target = e.target.getAttribute('href');
        var targetEl = document.getElementById(target.substr(2));



        if (targetEl !== null && location.pathname == '/') {
          e.preventDefault();
          var top = utility.getCoords(targetEl).top - utility.navHeight;
          console.log(utility.navHeight);
          history.replaceState(target, "Mirinda", target.substr(1));
          scrollTo(document.body, top, 600);
        }
      });
  });

  function scrollTo(element, to, duration) {
    var start = window.pageYOffset || element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

    var animateScroll = function(){
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);

        window.scrollTo(0, val);

        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
    utility.highlightActiveLink();
  }

  //t = current time
  //b = start value
  //c = change in value
  //d = duration
  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
  };

  window.addEventListener('orientationchange', function() {
    if (location.hash.length >= 1) {
      var targetEl = document.querySelector(location.hash);
      if (targetEl != null) {
        window.setTimeout(function() {
          var top = utility.getCoords(targetEl).top - utility.navHeight;
          scrollTo(document.body, top, 600);
        }, 500);
      }
    }
  });

}

module.exports = scrollToSection;