var myCarousel = function() {
  var carousel = document.querySelector('.carousel');
  var slider = document.querySelector('.carousel__slider');
  var arrows = Array.prototype.slice.call(document.querySelectorAll('span.next, span.prev'));
  var slidesArr = buildSlidesArr();
  var numDots = slidesArr.length;
  var slideAnimationDuration = 800;
  var heightAnimationDuration = 400;
  var currentIndex;
  var windowInterval;

  if (carousel != null) {
    if (slidesArr.length > 1) {
      currentIndex = 0;
      /* Necessary for keyboard controls */
      carousel.setAttribute('tabindex', -1);

      sizeSlider();
      setupDots();
      setSliderPos(currentIndex);
      setupListeners();
      slideTo(currentIndex);

      /* This needs to be done after the initial render */
      slider.style.transition = 'left '+(slideAnimationDuration/1000)+'s, height '+(heightAnimationDuration/1000)+'s';

      windowInterval = window.setInterval(function() {
        slideTo(currentIndex+1);
      }, 3000);
    } else {
      document.querySelector('span.prev').classList.add('hidden');
      document.querySelector('span.next').classList.add('hidden');
      slider.style.left = 0;
      slidesArr[0].style.left = 0;
    }
  }

  function buildSlidesArr() {
    var slides = document.querySelectorAll('.carousel__slider__slide');
    return Array.prototype.slice.call(slides);
  }

  function sizeSlider() {

    slider.style.width = (carousel.clientWidth * numDots) + 'px';
    // slider.style.height = slidesArr[currentIndex].offsetHeight + 'px';
    // slider.style.left = -carousel.clientWidth + 'px';

    slidesArr.map(function(current) {
      current.style.left = carousel.clientWidth * slidesArr.indexOf(current) + 'px';
    });
  }

  function setupDots() {
    var dotsFrag = document.createDocumentFragment();
    var dotsList = document.createElement('ul');
    dotsList.className = 'carousel__dots';

    for (var i=0; i<numDots; i++) {
      var newDot = document.createElement('li');
      newDot.className = 'carousel__dots__dot';
      dotsList.appendChild(newDot);
    }
    dotsFrag.appendChild(dotsList);
    document.querySelector('.carousel').appendChild(dotsFrag);
  }

  function setSliderPos(index) {
    if (document.querySelector('.carousel__dots__dot.active') != null) {
      document.querySelector('.carousel__dots__dot.active').classList.remove('active');
    }
    document.querySelector('.carousel__dots__dot:nth-child('+(index+1)+')').classList.add('active');
    slider.style.left = -1 * carousel.clientWidth * index + 'px';
  }

  function slideTo(index) {
    var currHeight = slidesArr[currentIndex].offsetHeight;

    if (index < 0) {
      currentIndex = slidesArr.length-1;
    } else if (index >= slidesArr.length) {
      currentIndex = 0;
    } else {
      currentIndex = index;
    }

    var newHeight = slidesArr[currentIndex].offsetHeight;
    if (newHeight < currHeight) {
      sizeSlider();
      window.setTimeout(function() {
        setSliderPos(currentIndex);
      }, heightAnimationDuration);
    } else if (newHeight > currHeight) {
      setSliderPos(currentIndex);
      window.setTimeout(function() {
        sizeSlider();
      }, slideAnimationDuration);
    } else {
      setSliderPos(currentIndex);
    }
    // window.setTimeout(sizeSlider, slideAnimationDuration);
    // setSliderPos(currentIndex);
    // sizeSlider();
  }

  function tempDisableInteraction() {
    arrows.map(function(current) {
      current.classList.add('disabled');
    });
    window.setTimeout(function() {
      arrows.map(function(current) {
        current.classList.remove('disabled');
      });
    }, slideAnimationDuration);
  }

  function setupListeners() {
    var touchStartX = 0;
    var touchEndX = 0;
    var touchTimer;
    document.querySelector('span.prev').addEventListener('click', function() {
      slideTo(currentIndex - 1);
      tempDisableInteraction();
      window.clearInterval(windowInterval);
    });
    document.querySelector('span.next').addEventListener('click', function() {
      slideTo(currentIndex + 1);
      tempDisableInteraction();
      window.clearInterval(windowInterval);
    });
    Array.prototype.slice.call(document.querySelectorAll('.carousel__dots__dot')).map(function(current, index) {
      current.addEventListener('click', function() {
        slideTo(index);
      });
      window.clearInterval(windowInterval);
    });

    slider.addEventListener('touchstart', function(e) {
      touchStartX = e.changedTouches[0].clientX;
      window.clearInterval(windowInterval);
    });
    slider.addEventListener('touchmove', function(e) {
      slider.style.left = -1 * ((carousel.clientWidth * currentIndex) + (touchStartX - e.changedTouches[0].clientX)) + 'px';
      window.clearInterval(windowInterval);
    });
    slider.addEventListener('touchend', function(e) {
      touchEndX = e.changedTouches[0].clientX;
      if (touchEndX > touchStartX) {
        slideTo(currentIndex-1);
      } else if (touchEndX < touchStartX) {
        slideTo(currentIndex+1);
      }
      window.clearInterval(touchTimer);
      window.clearInterval(windowInterval);
    });
    carousel.addEventListener('keyup', function(e) {
      var code = e.keyCode || e.which || e.key;
      if (code == 37 || code == 'ArrowLeft') {
        slideTo(currentIndex-1);
      } else if (code == 39 || code == 'ArrowRight') {
        slideTo(currentIndex+1);
      }
    });
    window.addEventListener('resize', function() {
      sizeSlider();
      slideTo(currentIndex);
    });
  }
}

module.exports = myCarousel;