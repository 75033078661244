// @ts-check

import 'slick-carousel';
import $ from 'jquery';

$.fn.unslick = function() {
    return this.each((index, element) => element.slick ? element.slick.destroy() : null);
};

let debounce;
let attachSlick;

let arrow = (cl) => `
    <button type="button" class="slick-${cl} slick-arrow"></button>
`;

let slickConfig = (slidesToShow = 1) => ({
    slidesToShow,
    slidesToScroll: 1,
    prevArrow: arrow('prev'),
    nextArrow: arrow('next')
});

let mobile = () => window.innerWidth <= 767;

(attachSlick = function() {
    $('.grid--carousel .grid--in-gutter__inner').each((index, el) => {
        let $el = $(el);

        $el.unslick();

        if (mobile()) {
            $el.find('style').remove();
            $el.slick(slickConfig(1));
        } else if ($el.find('.grid__placeholder').length > 4) {
            $el.find('style').remove();
            $el.slick(slickConfig(4));
        }
    });
})();

window.addEventListener('resize', () => {
    clearTimeout(debounce);
    debounce = setTimeout(attachSlick, 500);
});