var $ = require('jquery');

var activeLink = function() {

    $('.container a').click(function(){

        $('.container .active').removeClass('active');
        $(this).addClass('active');

    });
};

module.exports = activeLink;