var utility = require('../vendor/utilityFunctions');

var repositionOnLoad = function() {
  (function() {

    if (location.hash.length >= 1) {
      var el = document.getElementById(location.hash.substr(1));
      var val = utility.getCoords(el).top;
      val-=utility.navHeight;
      window.scrollTo(0, val);
      utility.highlightActiveLink();
    }
  })();
}

module.exports = repositionOnLoad;