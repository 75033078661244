import $ from 'jquery';
import { getCookies, saveCookie, expiresDays } from '../utils';

const $modal  = $('.js-cookies'),
      $accept = $('.js-cookies-accept'),
      $reject = $('.js-cookies-reject'),
      gaKey   = $modal.attr('data-ga');

const accept = () => {
    saveCookie('cookie_accept', true, expiresDays(365));
    $modal.removeClass('show');
    gaEnable();
};

const reject = () => {
    saveCookie('cookie_accept', false, expiresDays(365));
    $modal.removeClass('show');
    gaDisable();
};

const gaDisable = () => {
    window[`ga-disable-${gaKey}`] = true;
}

const gaEnable = () => {
    window[`ga-disable-${gaKey}`] = undefined;

    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer',gaKey);

    // (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function()
    // { (i[r].q=i[r].q||[]).push(arguments)}
    // ,i[r].l=1*new Date();a=s.createElement(o), 
    // m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m) 
    // })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');


    // if (typeof ga !== 'function') return;

    // ga('create', gaKey, 'auto');
    // ga('send', 'pageview');
}

if (!getCookies().cookie_accept) {
    $modal.addClass('show');
} else if (getCookies().cookie_accept === 'true') {
    gaEnable();
} else {
    gaDisable();
}

$accept.on('click', accept);
$reject.on('click', reject);