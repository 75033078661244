var utility = {};


utility.getCoords = function(elem) { // crossbrowser version
    var box = elem.getBoundingClientRect();

    var body = document.body;
    var docEl = document.documentElement;

    var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    var clientTop = docEl.clientTop || body.clientTop || 0;
    var clientLeft = docEl.clientLeft || body.clientLeft || 0;

    var top  = box.top +  scrollTop - clientTop;
    var left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
}

utility.highlightActiveLink = function() {
    var hash = location.hash;

    if (document.querySelector('.nav__list__item__link.underline') != null) {
        document.querySelector('.nav__list__item__link.underline').classList.remove('underline');
    }

    if (document.querySelector('.nav__list__item__link[href*="'+hash+'"]') != null) {
        document.querySelector('.nav__list__item__link[href*="'+hash+'"]').classList.add('underline');
    }
}

utility.findAncestor = function(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
}

utility.navHeight = window.matchMedia('(max-width: 768px)').matches ? 42 : 60;

module.exports = utility;