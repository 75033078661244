var embedVideo = function() {
  if (document.querySelector('.banner--video')) {
    Array.prototype.slice.call(document.querySelectorAll('.banner__container__video'))
      .map(function(currentValue) {
      
      // This is encoded by the server before rendering
      var code = decodeURIComponent(currentValue.getAttribute('data-embed'));
      currentValue.innerHTML = code;
    });
  }
}

module.exports = embedVideo;