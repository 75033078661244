import $ from 'jquery';

export default () => {
    const path = window.location.pathname.replace(/\//g, '');

    $('.tile').each((i, e) => {
        const $a = $(e).find('a');

        if ($a && $a.attr('href') && $a.attr('href').replace(/\//g, '') === path) {
            $('.nav .nav__list__item__link[href="/#products"]').addClass('active');
            $(e).css('opacity', 0.5);
        }
    });
};