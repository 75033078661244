export const getCookies = () => {
    if (!document.cookie) return {};
    
    let rawCookies = document.cookie.split(';'),
        cookies    = {};

    for (let i = 0; i < rawCookies.length; i++) {
        let s = rawCookies[i].split('=');

        cookies[s[0].trim()] = s[1].trim(); 
    }

    return cookies;
}

export const removeCookie = name => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const expiresDays = (days = 365) => {
    let date = new Date();
    date.setTime(+ date + (days * 86400000));
    return date.toGMTString();
};

export const saveCookie = (name, value, expires = '') => {
    document.cookie = `${name}=${value}${expires ? `;expires=${expires}` : ''}`;
};